@use '../_portal-variables' as tresplay;
@use '../_global-variables' as global;

.trescat {
    @font-face {
        font-family: 'Roboto';
        font-style: italic;
        font-weight: 100;
        font-display: swap;
        src: url(/_resources/fonts/roboto-italic-100.woff2?v=1.2503.10) format('woff2');
    }
    @font-face {
        font-family: 'Roboto';
        font-style: italic;
        font-weight: 300;
        font-display: swap;
        src: url(/_resources/fonts/roboto-italic-300.woff2?v=1.2503.10) format('woff2');
    }
    @font-face {
        font-family: 'Roboto';
        font-style: italic;
        font-weight: 400;
        font-display: swap;
        src: url(/_resources/fonts/roboto-italic-400.woff2?v=1.2503.10) format('woff2');
    }
    @font-face {
        font-family: 'Roboto';
        font-style: italic;
        font-weight: 500;
        font-display: swap;
        src: url(/_resources/fonts/roboto-italic-500.woff2?v=1.2503.10) format('woff2');
    }
    @font-face {
        font-family: 'Roboto';
        font-style: italic;
        font-weight: 700;
        font-display: swap;
        src: url(/_resources/fonts/roboto-italic-700.woff2?v=1.2503.10) format('woff2');
    }
    @font-face {
        font-family: 'Roboto';
        font-style: italic;
        font-weight: 900;
        font-display: swap;
        src: url(/_resources/fonts/roboto-italic-900.woff2?v=1.2503.10) format('woff2');
    }
    @font-face {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 100;
        font-display: swap;
        src: url(/_resources/fonts/roboto-100.woff2?v=1.2503.10) format('woff2');
    }
    @font-face {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 300;
        font-display: swap;
        src: url(/_resources/fonts/roboto-300.woff2?v=1.2503.10) format('woff2');
    }
    @font-face {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url(/_resources/fonts/roboto-400.woff2?v=1.2503.10) format('woff2');
    }
    @font-face {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-display: swap;
        src: url(/_resources/fonts/roboto-500.woff2?v=1.2503.10) format('woff2');
    }
    @font-face {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-display: swap;
        src: url(/_resources/fonts/roboto-700.woff2?v=1.2503.10) format('woff2');
    }
    @font-face {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 900;
        font-display: swap;
        src: url(/_resources/fonts/roboto-900.woff2?v=1.2503.10) format('woff2');
    }
    @font-face {
        font-family: 'Roboto Condensed';
        font-style: normal;
        font-weight: 300;
        font-display: swap;
        src: url(/_resources/fonts/roboto-condensed-300.woff2?v=1.2503.10) format('woff2');
    }
    @font-face {
        font-family: 'Roboto Condensed';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url(/_resources/fonts/roboto-condensed-400.woff2?v=1.2503.10) format('woff2');
    }
    @font-face {
        font-family: 'Roboto Condensed';
        font-style: normal;
        font-weight: 700;
        font-display: swap;
        src: url(/_resources/fonts/roboto-condensed-700.woff2?v=1.2503.10) format('woff2');
    }
    @font-face {
        font-family: 'Roboto Slab';
        font-style: normal;
        font-weight: 300;
        font-display: swap;
        src: url(/_resources/fonts/roboto-slab-300.woff2?v=1.2503.10) format('woff2');
    }
    @font-face {
        font-family: 'Roboto Slab';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url(/_resources/fonts/roboto-slab-400.woff2?v=1.2503.10) format('woff2');
    }
    @font-face {
        font-family: 'Roboto Slab';
        font-style: normal;
        font-weight: 500;
        font-display: swap;
        src: url(/_resources/fonts/roboto-slab-500.woff2?v=1.2503.10) format('woff2');
    }
    @font-face {
        font-family: 'Roboto Slab';
        font-style: normal;
        font-weight: 700;
        font-display: swap;
        src: url(/_resources/fonts/roboto-slab-700.woff2?v=1.2503.10) format('woff2');
    }
    @font-face {
        font-family: 'Roboto Slab';
        font-style: normal;
        font-weight: 900;
        font-display: swap;
        src: url(/_resources/fonts/roboto-slab-900.woff2?v=1.2503.10) format('woff2');
    }

    @font-face {
        font-family: 'Bw 3cat';
        src: url(/_resources/fonts/Bw3Cat-Black.woff2?v=1.2503.10) format('woff2'),
            url(/_resources/fonts/Bw3Cat-Black.woff?v=1.2503.10) format('woff');
        font-weight: 700;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Bw 3cat';
        src: url(/_resources/fonts/Bw3Cat-Black.woff2?v=1.2503.10) format('woff2'),
            url(/_resources/fonts/Bw3Cat-Black.woff?v=1.2503.10) format('woff');
        font-weight: 900;
        font-style: normal;
        font-display: swap;
    }

    // MIDES TEXT
    --headline_xl: 3.653rem;
    --headline_l: 1.688rem;
    --headline_m: 1.5rem;
    --headline_s: 1.312rem;
    --headline_xs: 1.188rem;
    --body_l: 1.062rem;
    --body_m: 0.938rem;
    --body_s: 0.875rem;
    --body_xs: 0.75rem;
    --body_xxs: 0.688rem;
    --body_xxxs: 0.438rem;

    // COLORS
    --clr_trans_0: #fff;
    --clr_trans_10: #e5e5e5;
    --clr_trans_20: #ccc;
    --clr_trans_30: #b2b2b2;
    --clr_trans_40: #999;
    --clr_trans_50: #808080;
    --clr_trans_60: #666;
    --clr_trans_70: #4d4d4d;
    --clr_trans_80: #333;
    --clr_trans_90: #1a1a1a;
    --clr_trans_95: #111;
    --clr_trans_100: #000;

    --clr_portal_140: #d71542;
    --clr_portal_100: #be2649;
    --clr_portal_80: #da3158;
    --clr_portal_60: #f0756e;

    --clr_portal_digital: #f61e50;
    --clr_marca_60: #f53c66;

    --clr_324_140: #993d00;
    --clr_324_100: #ff660d;
    --clr_324_60: #ffa366;

    --clr_esport3_140: #997a00;
    --clr_esport3_100: #ffcc00;
    --clr_esport3_60: #ffed66;

    --clr_cultura_140: #1a1a1a;
    --clr_cultura_100: #27b3e8;
    --clr_cultura_60: #999;

    --clr_cuines_100: #c84191;

    // GAP
    --gapRow: 56px;

    // MIDES TEXT DESKTOP
    @media (min-width: 768px) {
        --headline_l: 2.887rem;
        --headline_m: 2.281rem;
        --headline_s: 1.802rem;
        --headline_xs: 1.424rem;
        --body_l: 1.125rem;
        --body_m: 1rem;
        --body_s: 0.889rem;
        --body_xs: 0.79rem;
        --body_xxs: 0.702rem;
    }
    @media #{global.$VP_BEYOND_TP} {
        // GAP
        --gapRow: 72px;
    }
    @media #{global.$VP_EXTRA_LARGE} {
        // GAP
        --gapRow: 96px;
    }

    .gapBefore {
        margin-top: 26px;
    }
    .gapAfter {
        margin-bottom: 26px;
    }
    .noGapAfter {
        margin-bottom: 0;
    }
}
